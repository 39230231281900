import React          from 'react';
import { Link }       from 'gatsby';
import { Divider }    from '../../components/divider/divider';
import { Title }      from '../../components/title/title';
import { Wrapper }    from '../../components/wrapper/wrapper.component';
import { cn }         from '../../util/bem';
import { getContent } from './get-content';

const className = cn('content');

export const Content = (props: {year: string}) => {
    const content = getContent();

    const t = {
        intro: {
            title: 'Introduction',
            color: 'key'
        },
        review: {
            title: `${ parseInt(props.year, 10) - 1 } - ${ props.year } in Review`,
            color: 'green'
        },
        planing: {
            title: 'PSI Strategic Planning',
            color: 'mint'
        },
        achievements: {
            title: 'Our Achievements',
            color: 'violet'
        }
    };

    return (
        <Wrapper className={ className() }>
            { Object.keys(content).map((key, index) => (
                <Wrapper key={ index }>
                    <Title
                        size="h3"
                        type="chapter"
                        color={ t[ key ].color }
                        margin="xxl"
                        maxWidth="93rem"
                    >
                        { t[ key ].title }
                    </Title>
                    <Wrapper
                        row
                        styles={ { flexWrap: 'wrap' } }
                        columns={ 3 }
                        md={ { columns: 2 } }
                        sm={ { columns: 1 } }
                    >
                        { content[ key ].filter((item) => item.fields.slug.includes(props.year)).map((article, aIndex) => (
                            <Wrapper
                                key={ aIndex }
                                padding={ { right: 'xxl', bottom: 'xxl' } }
                                margin={ { bottom: 'sm' } }
                                hover={ { color: 'secondary' } }
                                xsm={ { padding: { right: 'off' }, margin: { bottom: 'xxs' }, color: 'key' } }
                            >

                                <Link
                                    key={ aIndex }
                                    className={ className('item') }
                                    to={ `/annual-report${ article.fields.slug }` }
                                    state={ {
                                        modal:    true,
                                        noScroll: true
                                    } }
                                    style={ { width: '100%' } }
                                >
                                    <Wrapper>
                                        <Divider color={ t[ key ].color } margin="md" />
                                        <Wrapper row>

                                            <Title
                                                size="h4"
                                                color={ t[ key ].color }
                                                uppercase
                                                margin="md"
                                            >
                                                { `${ index + 1 }.${ article.frontmatter.order }` }
                                            </Title>
                                            <Title
                                                size="h4"
                                                uppercase
                                                margin="xxs"
                                                hover={ { color: t[ key ].color } }
                                            >
                                                { article.frontmatter.title }
                                            </Title>
                                        </Wrapper>
                                    </Wrapper>
                                </Link>
                            </Wrapper>
                        )) }
                    </Wrapper>
                </Wrapper>

            )) }
        </Wrapper>
    );
};
