import { graphql, useStaticQuery } from 'gatsby';

export const getAllPosts = () => {
    const data = useStaticQuery(graphql`
    query GetAllPostsAnnualReport {
      allMdx(sort: {fields: frontmatter___order}, filter: {fileAbsolutePath: {regex: "/(annual-report)/"}}) {
        nodes {
          headings {
            value
            depth
          }
          fields {
            slug
          }
          frontmatter {
            chapter
            order
            title
            author
#            authorsMeta
          }
        }
      }
    }
  `);
    return data.allMdx.nodes;
};

export const getContent = () => {
    const content = {
        intro:        [],
        review:       [],
        planing:      [],
        achievements: []
    };

    const articles = getAllPosts();
    articles.map((article) => {
        const a = article.frontmatter;
        if (a.chapter) {
            content[ a.chapter ].push(article);
        }
        return null;
    });

    return content;
};

export const t = {
    intro: {
        title: 'Introduction - Summarising the Dynamic School Year Back',
        color: 'key'
    },
    review: {
        title: '2019-2020 in Review',
        color: 'green'
    },
    planing: {
        title: 'PSI Strategic Planning',
        color: 'mint'
    },
    achievements: {
        title: 'Our Achievements',
        color: 'violet'
    }
};
