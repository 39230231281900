import React             from 'react';
import { navigate }      from 'gatsby';
import { Content }       from 'lib/annual-report/content/content.component';
import { SiteLayout }    from 'lib/container/site-layout/site-layout';
import { ParallaxImage } from '../../../lib/components/parallax-image/parallax-image.component';
import { Seo }           from '../../../lib/components/seo/seo.componet';
import { Wrapper }       from '../../../lib/components/wrapper/wrapper.component';
import { TopBar }        from '../../../lib/container/top-bar/top-bar';
import { useGetImgSet }  from '../../../lib/util/get-images';

const AnnualReport2021 = () => {
    const img = useGetImgSet([ 'annual_report_2021_cover', 'annual_report_2021_cover_share' ]);
    return (
        <SiteLayout>
            <Seo
                title="Annual Report 2020/2021"
                image={ img.annual_report_2021_cover_share.src }
            />
            <TopBar onClick={ () => navigate('/') } />
            <Wrapper>
                <h1 style={ { fontSize: '12vw', lineHeight: 1, marginBottom: '1rem', maxWidth: '100vw' } }>
                    ANNUAL REPORT
                    { ' ' }
                    <em style={ { fontSize: '8vw' } }>2020 / 2021</em>
                </h1>
                <Wrapper// styles={ { height: '120px' } }
                    flex={ 1 }
                    margin="xxl"
                />
                { ' ' }
                <ParallaxImage
                    src={ img.annual_report_2021_cover.src }
                    height={ 500 }
                    margin="xxl"
                />
                { ' ' }
                <Content year="2021" />

            </Wrapper>

        </SiteLayout>
    );
};
export default AnnualReport2021;
